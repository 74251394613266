<template>
  <div class="landingpage">
    <h1>This is an about page!</h1>

  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  }
}
</script>
